$main-background: #202124;
$primary-base: #3333FF;
$primary-lighten2: #2698FF;
$primary-lighten1: #2357E8;
$primary-darken1: #5923E8;
$primary-darken2: #9C26FF;

$min-screen-desktop: 601px;
$max-screen-phone: 600px;

@font-face {
	font-family: 'United';
	src: local('UnitedSansReg-Medium'), url('./UnitedSansReg-Medium.otf') format('opentype');
}

body {
	margin: 0;
	font-family: 'United', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: $main-background;
	color: white;
	font-size: 1vw;

	* {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;

		&:link {
			color: $primary-lighten2;
		}
		
		&:visited {
			color: $primary-lighten2;
		}
	}
	
	svg {
		clip-rule:evenodd;
		fill-rule:evenodd;
		height: 100%;
		image-rendering:optimizeQuality;
		shape-rendering:geometricPrecision;
		text-rendering:geometricPrecision;
		width: auto;
	}

	#app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
}