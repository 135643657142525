.graduations-container {
	position: relative;

	.graduation {
		position: absolute;
		width: 1px;
		height: 10px;
		background-color: black;
		top: -5px;
	}

	hr {
		height: 1px;
		background-color: black;
		border: none;
		margin: 0;
	}
}