@import '_varsCSS';

.section {
	align-items: center;
	display: flex;
	margin-bottom: 5vw;
	margin-left: 2vw;

	@media (min-width: $min-screen-desktop) {
		margin-bottom: 2vw;
	}

	img {
		align-self: start;
		border-radius: 50%;
		width: 10vw;

		@media (min-width: $min-screen-desktop) {
			width: 5vw;
		}
	}

	.section-data {
		margin-left: 5vw;

		@media (min-width: $min-screen-desktop) {
			margin-left: 2vw;
		}
	}
}