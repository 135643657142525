@import '_varsCSS';

.blaze {
	display: flex;
	justify-content: center;
	font-size: 10vw;
	padding: 1.7vw 0;
	text-transform: uppercase;
	
	@media (min-width: $min-screen-desktop) {
		font-size: 1.7vw;
	}
}

.colomn-list {
	display: flex;
	justify-content: space-around;
	padding: 0.8vw 0;
	text-transform: uppercase;
	
	@media (min-width: $min-screen-desktop) {
		font-size: 0.9vw;
	}

	span {
		color: darken(rgb(155, 255, 255), 20);
	}

	&.brain {
		flex-direction: column;
		padding: 0.8vw 1vw;

		.cpu {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 2vw;

			@media (min-width: $min-screen-desktop) {
				margin-bottom: 1vw;
			}

			.cpu-name {
				text-transform: initial;

				@media (min-width: $min-screen-desktop) {
					font-size: 0.8vw;
				}
			}
		}

		.cpu-data {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 2vw;

			@media (min-width: $min-screen-desktop) {
				margin-bottom: 1vw;
			}

			canvas {
				border-bottom: 1px dashed darken(rgb(155, 255, 255), 20);
				border-top: 1px dashed darken(rgb(155, 255, 255), 20);
				height: 75px;
				width: 75%;

				@media (min-width: $min-screen-desktop) {
					height: 50px;
					width: 85%;
				}
			}
		}
	}

	.colomn-list {
		padding: 0.8vw 0 0 0;
	}

	div {
		text-align: center;
	}
}

