@import '_varsCSS';

.content {
	color: $primary-lighten2;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-size: 3vw;
	margin: 1vw;

	@media (min-width: $min-screen-desktop) {
		flex-direction: row;
		font-size: 1vw;
		justify-content: space-around;
		margin: 1vw 0;
	}

	.panel {
		display: flex;
		flex-direction: column;
		height: fit-content;
		margin-bottom: 2vw;

		@media (min-width: $min-screen-desktop) {
			margin-bottom: 0;
		}

		&.panel-center {
			padding: 0 2px;

			@media (min-width: $min-screen-desktop) {
				height: auto;
				width: 65vw;
			}
		}

		.panel-header {
			display: flex;
			font-size: 10px;
			justify-content: space-between;
			padding: 0 0.5vw;
			text-transform: uppercase;
		}

		.panel-content {
			margin: 1vw;
			margin-top: 2vw;
			
			@media (min-width: $min-screen-desktop) {
				margin: 0;
				width: 16vw;
			}

			&.others {
				align-items: center;
				display: flex;
				flex-direction: column;
			}

			.graduations-container {
				margin: 0;
			}
		}

		.graduations-container {
			margin: 0 0 1vw 0;
		}
	}

	.content-container {
		border: 1px solid $primary-lighten2;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-x: hidden;
		width: 100%;

		.container {
			padding-top: 1vw;

			@media (min-width: $min-screen-desktop) {
				display: flex;
				flex-grow: 1;
				height: 1px;
				overflow-x: hidden;
				scrollbar-width: thin;
			}
		}
	}
}