@import '_varsCSS';

.about {
	display: flex;
	flex-direction: column;

	.title {
		align-self: center;
		margin-bottom: 2vw;
	}
	
	.lightColor {
		background: linear-gradient($primary-lighten2, $primary-lighten1, $primary-darken1, $primary-darken2);
		display: flex;
		flex-direction: column;
		margin-bottom: 2vw;

		.title {
			align-self: center;
			color: $main-background;
			margin: 2vw;
		}
	}
	
	.box-container{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		width: 100%;

		.box{
			background: $main-background;
			border-radius: 30px;
			margin: 0 0 3vw 0;
			padding: 2vw 0;
			text-align: center;
			width: 60%;

			@media (min-width: $min-screen-desktop) {
				width: 30%;
			}
		}
	}
}

.timeline-container{
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.timeline-container div{
	align-items: center;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	width: 30%;
}

.timeline-container div img{
	max-height: 20%;
	max-width: 70%;
}

.timeline{
	max-width: 45vw;
	padding: 0;
}

.timeline.left{
	border-left: 4px solid $primary-lighten2;
	margin-left: 15%;
	text-align: left;
}

.timeline.right{
	border-right: 4px solid $primary-lighten2;
	margin-right: 15%;
	text-align: right;
}

.timeline li{
	list-style: none;
	position: relative;
	padding: 3vw;
	padding-bottom: 1vw;
	padding-top: 2vw;
}

.timeline li:before{
	content: attr(data-date);
	position: absolute;
	top: 3.5vw;
}

.timeline.left li:before{
	left: -30%;
}

.timeline.right li:before{
	right: -30%;
}

.timeline li:after{
	background: $main-background;
	border: 4px solid $primary-lighten2;
	border-radius: 50%;
	content: "";
	height: 1vw;
	position: absolute;
	top: 3.1vw;
	width: 1vw;
}

.timeline.left li:after{
	left: -1vw;
}

.timeline.right li:after{
	right: -1vw;
}