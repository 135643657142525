@import '_varsCSS';

footer{
	align-items: center;
	background: linear-gradient($primary-lighten2, $primary-darken1);
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 5vw;
	width: 100%;
	
	.credits{
		font-size: 12px;
		font-weight: 900;
		margin: 0.5vw;
	}
}