@import '_varsCSS';

.contact {
	align-self: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-around;
	width: 100%;

	.contact-container {
		display: flex;
		justify-content: space-around;
		margin-bottom: 2vw;

		@media (min-width: $min-screen-desktop) {
			margin-bottom: 1vw;
		}

		.contact-item {
			align-items: center;
			display: flex;
			flex-direction: column;

			a img {
				border-radius: 10px;
				width: 25vw;
				
				@media (min-width: $min-screen-desktop) {
					border-radius: 20px;
					width: 15vw;
				}
			}

			.title {
				margin-bottom: 0.5vw;
				text-transform: uppercase;
				width: fit-content;
			}
		}
	}
}