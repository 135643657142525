@import '_varsCSS';

.home {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-left: 1vw;

	@media (min-width: $min-screen-desktop) {
		align-items: unset;
		flex-direction: row;
	}

	img {
		border-radius: 50%;
		height: 70vw;
		margin-bottom: 5vw;
		object-fit: cover;
		object-position: top;
		width: 70vw;

		@media (min-width: $min-screen-desktop) {
			height: 15vw;
			margin-bottom: 0;
			margin-right: 2vw;
			width: 15vw;
		}
	}

	.description {
		display: flex;
		flex-direction: column;
		padding: 1vw;
		width: 100%;

		@media (min-width: $min-screen-desktop) {
			width: auto;
		}

		h1 {
			align-self: center;
			margin-bottom: 1vw;

			@media (min-width: $min-screen-desktop) {
				align-self: unset;
			}
		}
	}
}