@import '_varsCSS';

.placeHolder {
	height: 6vw;
}

.toolbar {
	ul {
		border-bottom: 1px solid $primary-lighten2;
		display: flex;
		list-style: none;

		.nav-item {
			cursor: pointer;
			flex-grow: 1;
			transform: skewX(45deg);

			&:first-child .item::before {
				content: "";
				height: 100%;
				left: 0;
				position: absolute;
				transform: skewX(-45deg) translate3d(-4px, -4px, 0);
				width: 50%;
			}

			&:last-child .item::before {
				content: "";
				height: 100%;
				position: absolute;
				right: 0;
				transform: skewX(-45deg) translate3d(-4px, -4px, 0);
				width: 50%;
			}

			&:last-child .item {
				border-right: none;
			}

			&:hover, &:first-child:hover .item::before, &:last-child:hover .item::before, &.active, &:first-child.active .item::before, &:last-child.active .item::before {
				background-color: darken(rgb(155, 255, 255), 20);
				color: $main-background;
			}
			
			.item {
				border-right: 1px solid $primary-lighten2;
				display: flex;
				justify-content: center;
				padding: 4px 0;
				text-transform: uppercase;

				span {
					transform: skewX(-45deg);
				}
			}
		}
	}
}